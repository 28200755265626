import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import ContentCarousel from 'components/common/contentCarousel'
import Cover from 'components/common/cover'
import WideCard from 'components/common/wideCard'
import UseCase from 'components/common/useCase'
import decisiveCard from '/static/images/platform/decisive_engine_bar.webp'
import decisive from '/static/images/platform/decisive_engine_hero.webp'
import { decisionFeatures } from '/static/data/platform/decisive.static'

const DecisiveEngine = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `Mlytics Decisive Engine: AI-Driven Application Optimization`,
            metaDescription: `Mlytics' Decisive Engine leverages AI to transform how your audience interacts with your applications, ensuring optimal performance and seamless user experiences.`,
            metaUrl: 'www.mlytics.com/platform/decisive-engine',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="Mlytics Decisive Engine"
            title="AI-driven Routing and Delivery at Global Scale"
            imgSrc={decisive}
            imgAlt="Platform Decisive Engine Hero Picture"
            paragraphContent="We innovate today for tomorrow’s gold standard of AI-driven application delivery."
            actionLinkOne="chatbot"
            actionTextOne="Learn Deeper"
          />
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <WideCard
              title="Precision in Milliseconds"
              imgSrc={decisiveCard}
              imgAlt="Precision in milliseconds"
              mainParagraph="In today's digital landscape, application delivery is more than just speed—it's about precision./n/Mlytics' Decisive Engine harnesses cutting-edge artificial intelligence to revolutionize how your audience experiences your content. "
              paragraphContent="Our AI-driven routing doesn't just react—it predicts. Using advanced algorithms and machine learning, it anticipates network issues before they occur, ensuring seamless content delivery even in the most challenging conditions."
              actionLink="chatbot"
              actionText="Learn More"
            />
          </div>
        </section>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <h2 className="h2-block-title text-black-100 text-center">Human-like Thinking, Lightning-Fast Decisions</h2>
            <ContentCarousel listItem={decisionFeatures} />
          </div>
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default DecisiveEngine

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
