import React from 'react'
import one from '/static/images/platform/decisive_engine_feature_1.webp'
import two from '/static/images/platform/decisive_engine_feature_2.webp'
import three from '/static/images/platform/decisive_engine_feature_3.webp'
import four from '/static/images/platform/decisive_engine_feature_4.webp'
import { clickMessageBirdChat } from 'utils'

export const decisionFeatures = [
  {
    title: 'Milliseconds matter. We make them count',
    content:
      "In digital content, every millisecond is crucial. The Decisive Engine processes vast amounts of data rapidly, making intelligent routing decisions that significantly reduce latency and elevate user experience. It doesn't just choose the fastest path—it chooses the smartest one, balancing performance with cost-efficiency to help you deliver superior experiences while optimizing your bottom line.",
    imageSrc: one,
    imageAlt: 'Milliseconds matter',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Chat with us.
      </button>
    )
  },
  {
    title: 'Borderless reach and tailored experiences SHOULD co-exist',
    content:
      'Mlytics enables you to expand your audience without sacrificing quality. With 214+ monitoring Points of Presence (PoPs) and over 30,000 Real User Monitoring locations worldwide, we optimize content delivery globally while ensuring peak performance for users in specific regions. Our granular control allows for country, state, and even city-level optimization in some areas.',
    imageSrc: two,
    imageAlt: 'Borderless reach',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Chat with us.
      </button>
    )
  },
  {
    title: 'Real-time monitoring for best experience',
    content:
      'Mlytics Decisive Engine evolves with every data point, continuously learning and adjusting to ensure optimal content delivery. This adaptive intelligence provides:',
    imageSrc: three,
    imageAlt: 'Real-time monitoring for best experience',
    children: (
      <div>
        <div className="ml-7">
          <ul>
            <li className="list-disc h5-subtext-medium text-black-70">
              Real-time switching between networks based on performance
            </li>
            <li className="list-disc h5-subtext-medium text-black-70">
              Predictive routing to avoid potential network issues
            </li>
            <li className="list-disc h5-subtext-medium text-black-70">Automatic failover to ensure maximum uptime</li>
            <li className="list-disc h5-subtext-medium text-black-70">
              Dynamic load balancing to optimize resource utilization
            </li>
          </ul>
        </div>
        <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
          Chat with us.
        </button>
      </div>
    )
  },
  {
    title: 'Efficiency meets intelligence, your bottom line wins',
    content: 'Mlytics helps you achieve the perfect balance between performance and cost. Our platform enables:',
    imageSrc: four,
    imageAlt: 'Efficiency meets intelligence',
    children: (
      <div>
        <div className="ml-7">
          <ul>
            <li className="list-disc h5-subtext-medium text-black-70">
              Reduction of content delivery costs by up to 25%
            </li>
            <li className="list-disc h5-subtext-medium text-black-70">
              Implementation of sophisticated approaches like AI-driven traffic routing
            </li>
            <li className="list-disc h5-subtext-medium text-black-70">
              Use of intelligent caching strategies to minimize unnecessary data transfer
            </li>
            <li className="list-disc h5-subtext-medium text-black-70">
              Maintenance of high availability and streaming quality while reducing expenses
            </li>
          </ul>
        </div>
        <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
          Chat with us.
        </button>
      </div>
    )
  }
]
